
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[favourite-modal] {
  a {
    color: $red;
  }

  .favourite-lists__element__icon {
    @include font-size(1.25rem);
    i {
      cursor: pointer;
    }
  }

  .favourite-lists__element__name {
    cursor: pointer;
    &:hover {
      color: $red;
    }
  }

  .favourite-lists__element__new-list {
    flex: auto;
    input {
      border-bottom: 1px solid $gray-300 !important;
      border: none;
      width: 95%;
      padding: 0.4rem 0;
    }
  }

  .modal-footer {
    p {
      margin: 0 !important;
    }
  }

  @include media-breakpoint-up(sm) {
    .modal-lg {
      max-width: 700px;
    }

    .modal-favourite {
      max-width: 610px;
    }
  }
}

