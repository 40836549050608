h1, .h1,
h2, .h2 {
  font-family: $neutra-display-font;
  font-weight: 400;
}

h3, .h3 {
  font-family: $italia-lt-font;
  font-weight: 700;
}

h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $neutra-text-font;
  font-weight: 700;
}

blockquote {
  padding-left: 6rem;
  padding-right: 6rem;
  margin: 2rem 0 !important;
  color: $primary;
  @include font-size(1.563rem); /* 25/16 */
}

.text-decoration-none {
  text-decoration: none;
}

.article-abstract {
  @include font-size(2.5rem);
  font-family: $italia-lt-font;
  color: $primary;
}

[stripe-header] {
  .title-composed {
    .title-italic {
      font-style: italic;
    }
    .title-uppercase {
      text-transform: uppercase;
    }
    .title-underline {
      text-decoration: underline;
    }
  }

  .favourite {
    i {
      @include font-size(1.75rem);
    }
  }
}

.link-black {
  text-decoration: none;

  @include hover {
    color: $primary;
    text-decoration: none;
  }
}

.link-red {
  color: $red;
  text-decoration: none;

  @include hover {
    color: darken($red, 10%);
    text-decoration: none;
  }
}
