
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$size: 2rem;
$transition-time: .5s;
$scale: 1.5;

.url {
  color: $black;
  transition: color $transition-time ease-in-out;
  text-decoration: none;
  width: 7rem;

  &.active,
  &:active,
  &:focus,
  &:hover {
    color: var(--color);

    .dot {
      transform: scale3d($scale, $scale, $scale);
    }
  }
}

.dot {
  border: 1px solid var(--color);
  transition: transform $transition-time ease-in-out;
}

.color-dot {
  width: $size;
  height: $size;
  background-color: var(--color);
  transition: background-color $transition-time ease-in-out;
}

.title {
  height: $size * 0.875;
  @include font-size($size / 2);
  white-space: nowrap;
}
