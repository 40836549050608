
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-recipe] {
  $img-padding: 67.5%;
  $avatar-size: 25%;
  $avatar-img-cover-size: 90%;

  .locked-layer {
    background-color: rgba($black, .3);
    z-index: 2;
  }

  .category-wrapper {
    line-height: 1;
  }

  .recipe-img-wrapper {
    padding-bottom: $img-padding;
  }

  .interactions-like,
  .interactions-favourite {
    color: $medium-grey;

    .icon {
      @include font-size(1.5rem);

      @include hover {
        color: $red;
      }
    }
  }

  .recipe-img-content {
    background-color: $light-grey;
  }
}
