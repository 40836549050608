
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


[css-grid] {
  --columns: 1;
  --span-col: 1;
  --span-row: 1;
  --row-size: 50vmax;

  @media (orientation: landscape) {
    --columns: 2;
  }
  
  @include media-breakpoint-up(sm) {
    --columns: 2;
    --span-col: 2;
    --row-size: 1fr;
  }
  
  @include media-breakpoint-up(md) {
    --columns: 4;
    --span-row: 2;
  }

  &.dark-theme {
    background-color: $dark;
    color: $white;

    .flickity-page-dots .dot {
      background-color: $white;
    }
  }

  .css-grid {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    grid-auto-rows: var(--row-size);
    grid-auto-flow: dense;
    grid-gap: 1rem;

    @include media-breakpoint-up(sm) {
      margin: 0 -1rem;
    }
    
    @include ie {
      display: flex;
      flex-flow: row wrap;
    }
  }

  .grid-item {
    padding-bottom: var(--default-tile-padding-bottom, 50%);

    &.inlinecontent {
      padding-bottom: 0;
    }
    
    &.largesquarecontent {
      grid-column: span var(--span-col);
      grid-row: span var(--span-row);
    }

    &.widecontent {
      grid-column: span var(--span-col);
    }

    &.highcontent {
      grid-row: span var(--span-row);
    }
    
    @include ie {
      margin: 1rem;
      width: calc(25% - 2rem);

      &.largesquarecontent,
      &.widecontent {
        width: calc(50% - 2rem);
      }

      &.highcontent {
        padding-bottom: 100%;
      }
    }
  }
}

