
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[simple-form-component] {
  .borderless-items {
    .form-control,
    .custom-select,
    .cuustom-file-input {
      border-width: 0px;
    }
  }
}
