
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-component] {
  color: $white;
  overflow: hidden;
  cursor: pointer;

  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  .linear-gradient-y {
    background: linear-gradient(to bottom, rgba($black, 0) 50%, $black);
    opacity: .16;
  }

  .title-composed,
  .title-simple {
    .title-italic {
      font-style: italic;
    }
    .title-uppercase {
      text-transform: uppercase;
    }
    .title-underline {
      text-decoration: underline;
    }
  }

  .is-big-title {
    font-family: $neutra-display-font;
    @include font-size(1.875rem);
    line-height: 1;

    @include media-breakpoint-up(lg) {
      @include font-size(2.25rem);

      .largesquarecontent & {
        @include font-size(2.75rem);
      }
    }
  }

  .is-small-title {
    @include font-size(1.35rem);
    line-height: 1.1;

    @include media-breakpoint-up(lg) {
      @include font-size(1.875rem);
    }
  }

  .favourite {
    i {
      @include font-size(1.75rem);
    }
  }

  .category {
    font-family: $italia-lt-font;
    letter-spacing: 0.96px;
  }

  .owner-info {
    .owner-avatar {
      height: 42px;
      width: 42px;
      border-radius: 50%;
    }
  }

  @include media-breakpoint-down(md) {
    .favourite {
      i {
        @include font-size(1.25rem);
      }
    }
  }

}
