.mh-100vh {
  min-height: 100vh; 
}

.h-100vh {
  height: 100vh;
}

.h-50vh {
  height: 50vh;
}

.mh-75vh {
  min-height: 75vh;
}

.mh-50vh {
  min-height: 50vh;
}

.mh-25vh {
  min-height: 25vh;
}

.h-60vh {
  height: 60vh;
}

.h-75vh {
  height: 75vh;
}

.h-100vh-header {
  height: calc(100vh - #{$header-height});
}

.mh-100vh-header {
  min-height: calc(100vh - #{$header-height});
}

.h-100vh-header-sm {
  height: calc(100vh - #{$header-height-sm});
}

.mh-100vh-header-sm {
  min-height: calc(100vh - #{$header-height-sm});
}

.ar-2-75by1 {
  padding-bottom: 36.363636%;

  @include media-breakpoint-down(sm) {
    padding-bottom: 56.25%;
  }
}


.w-100vw {
  width: 100vw;
}

.ar-2by3 {
  padding-bottom: 150%;
}

.ar-2by1 {
  padding-bottom: 50%;
}

.ar-16by9 {
  padding-bottom: 56.25%;
}

.ar-9by16 {
  padding-bottom: 177.7777%;
}

.ar-4by3 {
  padding-bottom: 75%;
}

.ar-3by4 {
  padding-bottom: 133.3333%;
}

.ar-6by5 {
  padding-bottom: 120%;
}

.ar-1by1 {
  padding-bottom: 100%;
}

.ar-4by5 {
  padding-bottom: 125%;
}

@include media-breakpoint-down(sm) {
  .h-100vh-header {
    height: calc(100vh - #{$header-height-sm});
  }

  .mh-100vh-header {
    min-height: calc(100vh - #{$header-height-sm});
  }

  .ar-sm-1by1 {
    padding-bottom: 100%;
  }
}
