
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-product-component] {

  .sidebar {
    width: 26.5rem;
    max-width: 100%;
  }

  [bookmark] .container, .bookmark__footer .container {
    width: 100% !important;
    max-width: none;
  }

  .show-sidebar {
    left: 1rem;
    z-index: 1;
    position: fixed;
    border-radius: 0;
    bottom: 1rem;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  }

  .course-product__sidebar {
    background-color: rgba($gray-200, .5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  @include media-breakpoint-down(sm) {
      
    .sidebar {
      position: fixed;
      width: 85%;
      top: 0;
      bottom: 0;
      left: 0;
      overflow-y: auto;
      z-index: $zindex-youtube-popup;
    }  
  }

}
