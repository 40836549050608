// official facebook blue
$facebook-blue: #4267b2;

$red: #c1252f;
$cream:  #fffcf7;
$gold: #d3b56c;
$light-red: #c84149;

// Grayscale
$black: #000000;
$white: #FFFFFF;

$dark-grey: #1e1e1e;
$medium-grey: #5a5a5a;
$light-grey: #b4bdb6;
$border-grey: #b8a889;
$military-green: #376129;


$common-color: $red;
$premium-color: $gold;

// Fandom variables overrides
$user-dashboard-height: 15.375rem;
$user-badge-bg-color: transparent;
$user-badge-icon-color: $white;

// Font families variables
$italia-lt-font: "Italia LT Book", Georgia, Times, serif;
$neutra-text-font: "Neutra Text", Helvetica, Arial, sans-serif;
$neutra-display-font: "Neutra Display", Helvetica, Arial, sans-serif;

// Put here Bootstrap™ project variables ovverides:
$font-family-base: $neutra-text-font;
$h1-font-size: 4.5rem;
$h2-font-size: 3.125rem;
$h3-font-size: 3.188rem; //35px
$h4-font-size: 2.5rem;
$h5-font-size: 2.25rem;
$h6-font-size: 1.825rem;
$font-size-base: 1.5rem;
$enable-responsive-font-sizes: true;
$rfs-base-value: 1.2rem;
$input-font-size: 1.25rem;

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 916px,
  lg: 1180px,
  xl: 1360px
);

$container-max-widths: (
  sm: 752px,
  md: 880px,
  lg: 1136px,
  xl: 1334px
);

$btn-border-radius: 1.6rem;
$btn-font-size: .8rem;
$btn-padding-x: 2.5rem;
$btn-padding-y: .8rem;
$btn-md-multiplier: 1.2;

$input-border-radius: 1rem;
$custom-select-border-radius: 1rem;
$input-border: $white;
$input-border-color: $gold;
$list-inline-padding: .5rem;

$header-height: 11.25rem;

$primary: $red;
// $secondary: $gold;

@import "~src/stylesheets/fandom_variables";

// Bind original bootstrap colors with official palette:
$agugiaro-figna-colors:(
  "dark-grey": $dark-grey,
  "medium-grey": $medium-grey,
  "light-grey": $light-grey,
  "white": $white,
  "black": $black,
  "cream": $cream,
  "red": $red,
  "gold": $gold,
  "light-red": $light-red,
  "military-green": $military-green
);

$colors: map-merge($colors, $agugiaro-figna-colors);
$theme-colors: map-merge($theme-colors, $agugiaro-figna-colors);

// CinqueStagioni

$bg-transition-zoom-time: 1.8s;
$bg-transition-zoom-scale: 1.08;
$transition-time-fast: .25s;
$navbar-height: 5rem;
$zindex-menu-item: 1030;
$top-level-item-height: 2rem;

$body-bg: $cream;
