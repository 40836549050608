
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.keypoint-icon {
  width: 6rem;

  @include media-breakpoint-up(xl) {
    width: 8rem;
  }
}

.keypoint-number {
  font-size: 3.1875rem;

  @include media-breakpoint-up(xl) {
    font-size: 3.5rem;
  }
}

.keypoint-title {
  font-size: 1.625rem;
  line-height: 1.27;

  @include media-breakpoint-up(xl) {
    min-height: 6rem;
    font-size: 1.875rem;
  }
}

.keypoint-description {
  font-size: 1.25rem;
  line-height: 1.27;
  color: $medium-grey;

  @include media-breakpoint-up(xl) {
    font-size: 1.625rem;
  }
}

.custom-border-right {
  border-right: solid 2px $border-grey;
}

@include media-breakpoint-down(xs){
  .border-top-xs {
    border-top: solid 2px #979797;
    margin: 0 2rem;
  }
}
