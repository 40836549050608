
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[breadcrumb-component] {
  background-color: transparent;

  .breadcrumb {
    background-color: transparent;
    font-family: $neutra-text-font;
    @include font-size(.75rem);
    line-height: 1.25rem;
    text-transform: uppercase;
    li {
      display: inline;
    }
    *, .breadcrumb-item > a, .breadcrumb-item + .breadcrumb-item::before {
      color: $medium-grey !important;
      font-weight: 300;
    }

    .breadcrumb-item {
      text-transform: initial;
      font-weight: normal !important;
    }

    .breadcrumb-item:last-child {
      font-weight: 600;
      color: $medium-grey !important;
    }

    .breadcrumb-item + .breadcrumb-item {
      padding-left: .125rem;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      content: ">";
    }

    @include media-breakpoint-down(xs) {
      li {
        padding-left: 0.2rem;
      } 
      
      li:first-child {
        padding-left: 0rem;
      }
    }
  }
}
