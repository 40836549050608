
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-carousel-component] {
  position: relative;

  .flickity-carousel {
    position: relative;

    .flickity-button {
      background: transparent;
    }

    .flickity-prev-next-button {
      height: 75px;
      width: 75px;
    }

    .flickity-prev-next-button.previous {
      left: -65px;
    }
    .flickity-prev-next-button.next {
      right: -65px;
    }

    .flickity-page-dots {
      bottom: .5rem;
      & .dot.is-selected {
        background-color: $black;
        border: 1px solid $black;
      }

      .dot {
        background-color: $white;
        border: 1px solid $white;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .flickity-prev-next-button,
    .flickity-prev-next-button {
      display: none;
    }
  }
}
