
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.w08 {
  stroke-width: .8;
}

.fs {
  fill: currentColor;
}

.ss {
  stroke: currentColor;
}
