
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-pizzeria-component] {
  color: $white;

  .thumbnail {
    padding-bottom: 43.6%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  h4 {
    @include font-size(1.5rem);
    color: $red;
    font-family: $italia-lt-font;
  }

  .address {
    @include font-size(.875rem);
    color: $black;
    line-height: 1.5;
    font-family: $neutra-text-font;
    font-weight: bold;
    border-bottom: 1px solid rgba(200,65,73,0.42);
  }

  p {
    @include font-size(1.125rem);
    color: $black;
  }
}
