
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-tile-component] {
  color: var(--theme-color);
  background-color: $gray-800;

  a:not(.btn) {
    color: var(--theme-color) !important;
  }

  .video-icon {
    right: 1rem;
    bottom: 1rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5;
    padding-left: 4px;
  }

  .ar-16by10 {
    padding-bottom: 62.5%;
  }


  .category {
    text-transform: uppercase;
  }

  .button-group {
    margin-left: -0.25rem;
    margin-right: -0.25rem;

    .btn {
      margin-left: 0.25rem;
      margin-right: 0.25rem;

      @include media-breakpoint-down(sm) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
      }
    }
  }
}
