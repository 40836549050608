.font-sm {
  @include font-size(0.875rem !important);
}

.font-xs {
  @include font-size(0.8rem !important);
}

.font-md {
  @include font-size(1.125rem !important);
}

.font-lg {
  @include font-size(1.25rem !important);
}

.font-xl {
  @include font-size(1.5rem !important);
}

.mirrored {
  transform: scaleX(-1);
}

.opacity-35 {
  opacity: .35;
}

.italia-lt-font {
  font-family: $italia-lt-font;
}

.bg-cover-center {
  background-position: center;
  background-size: cover;
}

.bg-cover-top {
  background-position: top;
  background-size: cover;
}

.bg-contain-center {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.bg-body-bg {
  background-color: $body-bg;
}

.bg-transparent {
  background-color: transparent !important;
}

.body-bg {
  background-size: 100%;
  background-repeat: repeat-y;
}

.custom-checkbox {
  @include font-size(.75rem);
  background-color: $white;
  transition: background-color .25s ease-in-out;

  &.red {
    &:active,
    &.active {
      background-color: $red;
    }
    &:hover, &:focus {
      background-color: lighten($red, 30%);
    }
  }

  &.gold {
    &:active,
    &.active {
      background-color: $gold;
    }
    &:hover, &:focus {
      background-color: lighten($gold, 30%);
    }
  }
}

@mixin lazy-styles($class, $loaded-class) {
  #{$class} {
    opacity: 0;
    transition: opacity .75s ease-in-out !important;
    will-change: opacity;

    &#{$loaded-class} {
      opacity: 1;
    }
  }
}

.form-label {
  font-weight: 700;
  @include font-size(1.25rem);
}

@include lazy-styles(".lazy-bg", ".lazy-bg-loaded");
@include lazy-styles(".lazy-img", ".lazy-img-loaded");

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $gray-300;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $gray-300;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $gray-300;
}

@each $color-name, $color-val in $agugiaro-figna-colors {
  .link-#{$color-name} {
    color: $color-val;
    transition: all 0.3s;
    &:hover, &:active, &:focus {
      color: darken($color-val, 15%);
    }
  }
}

@each $color-name, $color-val in $agugiaro-figna-colors {
  .bg-hover-#{$color-name} {
    transition: all 0.3s;
    &:hover, &:active, &:focus, &.active {
      background-color: $color-val;
    }
  }
}

$widths:("20": 20%, "22": 22%, "24": 24%, "26": 26%, "28": 28%, "30": 30%);
@each $width, $width-val in $widths {
  .w-#{$width} {
    width: $width-val !important;
  }
}

// TODO: rename in w-sm-down-*
@include media-breakpoint-down(sm) {
  $sm-widths:("25": 25%, "50": 50%, "75": 75%, "100": 100%);
  @each $width, $width-val in $sm-widths {
    .w-sm-#{$width} {
      width: $width-val !important;
    }
  }
}

.checkbox-custom input[type=checkbox] {
  visibility: hidden;
}

.checkbox-custom {
  position: relative;
  padding: 4px;
}

.checkbox-custom label {
  cursor: pointer;
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  top: 0;
  left: 0;
  background: $white;
}

.checkbox-custom label:after {
  position: absolute;
  width: 11px;
  height: 7px;
  top: 5px;
  left: 5px;
  border: 2px solid $white;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}

.checkbox-custom label:hover {
  border: 1px solid $dark;
}

.checkbox-custom input[type=checkbox]:checked + label {
  color: $white;
  background-color: $white;
}

.checkbox-custom input[type=checkbox]:checked + label:after {
  content: '';
  border-color: $dark !important;
  opacity: 1;
}

// Radio Button

.custom-radio {

  .container-radio {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;
  }

  .radio-recipe-ugc[type="radio"]:checked::-ms-check {
    border: 2px solid #aaa;
    color: #aaa;
    opacity: 1;
    @include font-size(larger);
  }

  .radio-recipe-ugc[type="radio"] {
    /* remove standard background appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 22px;
    height: 22px;
    padding: 4px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #000000;
    opacity: 0.4;
    vertical-align: bottom;
    border-radius:50%;
  }

  /* appearance for checked Radio button */
  .radio-recipe-ugc[type="radio"]:checked , .radio-recipe-ugc[type="radio"]:disabled:checked{
    background-color: #aaa;
    border: 2px solid #aaa;
    opacity: 1;
  }

}

// Default loader
.agugiaro-figna-loader {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.agugiaro-figna-loader:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid $medium-grey;
  border-color: $medium-grey transparent $medium-grey transparent;
  animation: agugiaro-figna-loader 1.2s linear infinite;
}

@keyframes agugiaro-figna-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Juicer heading style */
.juicer-content {
  p {
    a {
      font-family: $italia-lt-font;
      font-style: italic;
      color: $dark;
      @include font-size(1.6875rem);
    }
  }

}

.premium-variable-bg {
  background-color: $common-color;
}

.premium-variable-bg-transparent {
  background-color: rgba($common-color, .2);
}

.premium-variable-border {
  border-color: rgba($common-color, .4) !important;
}

.premium-variable-text {
  color: $common-color;
}

.btn-premium-variable {
  color: $common-color !important;
  border-color: $common-color;

  @include hover {
    color: $white !important;
    background-color: $common-color;
  }
}

.premium-variable-text-hover {
  @include hover {
    color: $common-color;
  }
}

.premium {
  .premium-variable-bg {
    background-color: $premium-color;
  }

  .premium-variable-bg-transparent {
    background-color: rgba($premium-color, .2);
  }

  .premium-variable-border {
    border-color: rgba($premium-color, .4) !important;
  }

  .premium-variable-text {
    color: $premium-color;
  }

  .btn-premium-variable {
    color: $premium-color !important;
    border-color: $premium-color;

    @include hover {
      color: $white !important;
      background-color: $premium-color;
    }
  }

  .premium-variable-text-hover {
    @include hover {
      color: $premium-color;
    }
  }
}

.bg-alpha-red {
  background-color: rgba($red, .65);
}

.bg-alpha-premium {
  background-color: rgba($gold, .65);
}

/* extra padding to center inside text */
.btn:before {
  content: '';
  padding-top: 3px;
  position: static;
  display: block;
}

.checkbox label.custom-checkbox.border {
  border-color: #A6AAA8 !important;
}

[youtube-popup-component] .youtube-popup-close {
  font-family: sans-serif;
}
