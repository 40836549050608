
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-recipe-categories-component] {

  .recipe-categories-carousel-item {
    width: 33.3333%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .flickity-page-dots {
    bottom: -4rem;
  }

  @include media-breakpoint-up(md) {
    .recipe-categories-carousel {

      .flickity-page-dots {
        display: none;
      } 

    }
  }

}
