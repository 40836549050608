.bg-cover-center {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-cover-top {
  background-position: top;
  background-size: cover;
}

.bg-contain-center {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-contain-top {
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}

.bg-contain-bottom {
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.bg-gradient-thin {
  background-color: rgba($black, 0.25);
}

.bg-gradient {
  background-color: rgba($black, 0.75);
}

.bg-black {
  background-color: $black;
}

.bg-transition {
  overflow: hidden; /* Background animation management. */

  .bg { /* Background animation management. */
    transition: transform $bg-transition-zoom-time;
    /*transform: scale(1);*/
  }

  &:hover .bg { /* Background animation management. */
    transform: scale($bg-transition-zoom-scale);
  }
}

.bg-fixed { /*Background with fixed image position*/
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}
