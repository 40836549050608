
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[test-layout] {
  h1, h2, h3, h4, h5, h6, p {
    &:hover {
      background-color: #eee;
    }
  }
  .title {
    color: $red;
  }

  .color-box {
    width: 96px;
    height: 96px;
    border: 1px solid #eee;
  }
}
