
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[cookies-banner-component] {
  background-color: rgba($white, .9);
  z-index: 1090;
  position: fixed;
  bottom: 0%;

  p {
    @include font-size(.75rem);
  }
}
