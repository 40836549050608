
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-selection-component] {
  .choice {
    border: 1px solid $primary;
    border-radius: 8px;
    transition: all .25s;
    color: $primary;
    background-color: $white;

    &:hover {
      color: darken($primary, 10%);
      border-color: darken($primary, 10%);
      background-color: darken($white, 3%);
    }

    &.selected {
      background-color: $primary;
      color: $white;
    }
  }
}
