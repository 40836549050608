
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[visual-sitemap-component] {
  h2 {
    font-family: $neutra-text-font;
  }
}
