
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-search-autocomplete-item-component] {
  .item-description {
    .match {
      font-weight: bold;
      color: $red !important;
    }
  }
}
