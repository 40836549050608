
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[footer-component] {

  position: relative;
  z-index: 5;

  .social-icon {

    @include font-size(1.375rem);

    @include media-breakpoint-down(sm) {
      @include font-size(2.5rem);
    }
  }

}
