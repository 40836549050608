
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-spacer-component] {
  --margin: 0;
  --padding: 0;
  margin-top: var(--margin);
  padding-top: var(--padding);
}
