
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-taste-map] {

  .ar-map {
    padding-bottom: 41.666667%;
    background-color: #ddd;
    @include media-breakpoint-down(sm) {
      padding-bottom: 125%;
    }
  }
  .input-label {
    @include font-size(1.25rem);
    font-family: $italia-lt-font;
  }

  input {
    max-width: 25rem;
  }

  .info-content {
    .title {
      @include font-size(1.5rem);
      color: $red;
      font-family: $italia-lt-font;
    }

    .description, a {
      font-family: $neutra-text-font;
      font-weight: bold;
    }

    .description {
      @include font-size(.875rem);
      color: $black;
      line-height: 1.5;
    }

  }

  button.gm-ui-hover-effect {
    right: 0 !important;
    top: 0 !important;

    &:hover {
      outline: none;
    }
  }

  .bg-layer {
    backdrop-filter: blur(6px);
    background-color: rgba(0,0,0,.35);

    h2 {
      font-family: $italia-lt-font;
    }
  }
}
