
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-carousel] {

  .img-container {
    max-width: 1000px;
  }

  .abstract-text {
    font-size: 26px;
    font-weight: bold;
  }

  .title-text {
    font-size: 38px;
  }

  .description-text {
    font-size: 20px;
    color: $medium-grey;
  }

  .mw-900 {
    max-width: 900px;
  }

  @include media-breakpoint-down(xs) { 
    .abstract-text {
      font-size: 20px;
    }

    .title-text {
      font-size: 34px;
    }

    .description-text {
      font-size: 18px;
      line-height: 26px;
    }

  }
}
