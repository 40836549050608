
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


[navbar-component] { 
  height: $navbar-height;

  .menu-item {
    z-index: $zindex-menu-item;
    transition: all $animation-time-fast;
    height: $top-level-item-height;
    @include font-size(1.25rem);
    padding-top: 4px;

    .hover-active-link {
      text-decoration: none;
      color: var(--active-color) !important;
    }
    background-color: var(--active-background-color);

    &:hover .sub-menu {
      display: block !important;
    }
  }

  .sub-menu {
    top: $top-level-item-height;
    background-color: $white;
    cursor: default;
    color: $dark;
    z-index: 1;
    left: 0;

    a.sub-item {
      white-space: pre;
      font-size: 1.125rem;
    }

    .url-lg {
      font-family: $italia-lt-font; // TODO: move into font variable;
    }

    .img-box > img {
      width: 100%;
      height: auto;
    }
  }
}
