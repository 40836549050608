
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-article] {

  .tile-url {
    @include hover {
      text-decoration: none;
    }
  }

  .tile-thumbnail {
    padding-bottom: 65%;
  }

  .category {
    line-height: 1;
  }
}
