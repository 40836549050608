
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$feedback-text-font-size: 1rem;
$feedback-icon-font-size: 1.5rem;
$feedback-width: 20rem;
$feedback-background-color: #F8F9FA;
$feedback-border-color: #DEE2E6;
$feedback-text-color: black;

[interaction-feedback-alert] {
  z-index: $zindex-feedback;
  width: $feedback-width;
  max-width: 90%;
  border-radius: 15rem;
  background-color: $feedback-background-color;
  border: 1px solid $feedback-border-color;


  .feedback-text {
    font-size: $feedback-text-font-size;
    font-weight: normal;
    color: $feedback-text-color;
  }

  i {
    font-size: $feedback-icon-font-size;
    color: green;
  }

}
