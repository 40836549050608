
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[pwa-banner-component] {
  background-color: rgba(#ca3f47, .95);
  color: white;
  z-index: 1090;
  position: fixed;
  top: 0;

  p {
    @include font-size(1rem);
    margin: 0;
  }

  .icon {
    width: 3rem;
    height: 3rem;
    border-radius: 10px;
  }

  .btn-light {
    color: #ca3f47;
    @include font-size(.75rem);
    font-weight: bold;
  }
}
