// Bootstrap product variables ovverides:

$input-border-radius: 0 !default;
$input-border-radius-lg: 0 !default;
$input-border-radius-sm: 0 !default;

$input-btn-padding-y: .75rem !default;
$input-btn-padding-x: 1.25rem !default;

@import "bootstrap_variables";

$fandom-colors: (
  "black": $black,
  "gray-light": $gray-300
);
$colors: map-merge($colors, $fandom-colors);

$instant-win-color: #fff200 !default;

$zindex-youtube-popup: 1090 !default;
$zindex-feedback: 1100 !default;

// Height used to aproximate the infinite height screen of the googlebot, used to prevent SEO issues with the vh values
$googlebot-seo-height: 140rem !default;
$header-height: 0px !default;
$header-height-sm: $header-height !default;
$secondary-nav-height: 0 !default;

$animation-time-slow: 0.75s !default;
$animation-time-medium: 0.5s !default;
$animation-time-fast: 0.25s !default;

$user-dashboard-bg-color: $gray-100 !default;
$user-dashboard-height: 15rem !default;
$user-dashboard-avatar-bg-color: $gray-600 !default;
$user-dashboard-avatar-color: $white !default;
$user-dashboard-recap-bg-color: $gray-600 !default;
$user-dashboard-recap-color: $white !default;

$bg-transition-zoom-time: 0.6s !default;
$bg-transition-zoom-scale: 1.075 !default;

$quote-color: #CCC !default;

// Badges variables
$user-badge-bg-color: #dee2e6 !default;
$user-badge-color: #000000 !default;
$user-badge-icon-color: $user-badge-bg-color !default;
$user-badge-icon-sizing: 5rem !default;
$user-badge-icon-font-size: 2rem !default;
$user-badge-modal-icon-sizing: $user-badge-icon-sizing * 1.5 !default;
$user-badge-modal-icon-font-size: $user-badge-icon-font-size * 1.5 !default;

// Levels variables
$user-level-bg-color: $gray-200 !default;
$user-level-inactive-bg-color: $gray-300 !default;
$user-level-color: #000000 !default;
$user-level-description-color: $white !default;
$user-level-icon-color: $user-level-bg-color !default;
$user-level-stroke-color: $gray-600 !default;
$user-level-stroke-bg-color: $gray-400 !default;
$user-level-icon-sizing: 37.5% !default;
$user-level-icon-font-size: 2rem !default;


$trivia-component-bg-color: #000000 !default;
$trivia-component-color: #ffffff !default;

$stripe-tiny-interactions-bg-color: $user-dashboard-bg-color !default;
$stripe-tiny-interactions-height: 4rem !default;

/* component page-live-event */
$page-live-event-interactive-sidebar-bg: $white;
$page-live-event-interactive-sidebar-color: $dark;

/* component grid-isotope */
$isotope-grid-gap-row: 30px;
$isotope-grid-gap-column: 30px;
/* component grid-fixed */
$fixed-grid-gap: 15px;

// video embed
$video-controlbar-height: 40px;

// Player
$player-control-bar-height: 60px;

$player-border-radius: 14px;
$player-border-radius-sm: 8px;

$player-red-color: #E74040;
$player-grey: #C5C5C9;

$player-colors: (
  "player-red-color": $player-red-color,
  "player-grey": $player-grey
);

$colors: map-merge($colors, $player-colors);
$theme-colors: map-merge($theme-colors, $player-colors);

$font-size-xs: .625rem;
$spacers: map-merge(
  (
    double: ($spacer * 2),
    medium: ($spacer * .8)
  ),
  $spacers
); 

// Quiz
$correct-color: #68C928;
$correct-dark-color: #4DAC1D;
$wrong-color: $player-red-color;
$wrong-dark-color: #B30B0E;