
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-carousel-story-component] {

  .flickity-page-dots {
    bottom: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    position: relative;

    .dot {
      margin: 0 .25rem;
    }
  }

  .flickity-prev-next-button {
    color: $white;
    background-color: $red;
  }


  .flickity-carousel-item {
    width: 80%;

    @media (orientation: landscape) {
      width: 40%;
    }

    @include media-breakpoint-up(sm) {
      width: 40%;
    }

    @include media-breakpoint-up(md) {
      width: 80%;
    }
  }
  
  .story-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;

    &-close, &-muted {
      text-decoration: none;
      @include font-size(2rem);
      top: 1rem;
      right: 1.7rem;
      z-index: 100;

      @include media-breakpoint-up(sm) {
        right: 1rem;
      }
    }

    &-muted {
      right: 3.5rem;
    }

    &-video {
      object-fit: cover;
    }
  }

  @include media-breakpoint-up(lg) {
    @for $i from 1 through 6 {
      .per-page-#{$i} {
        .flickity-carousel-item {
          width: 100% / $i;
        }
      }
    }
  }
  
  .flickity-carousel-story {
    .flickity-page-dots {
      .dot {
        width: 1.5rem;
        max-width: var(--dot-width-mobile);
        height: .125rem;
        margin: 0 .25rem;
        background: $white;
        border-radius: 0;
        opacity: 0.25;
        cursor: pointer;

        &.is-selected {
          opacity: 1;
        }

        @include media-breakpoint-up(sm) {
          width: 1.25rem;
          max-width: auto;
        }
      }
    }

    @include media-breakpoint-down(xs) {
      .flickity-page-dots {
        position: absolute;
        top: 0;
        bottom: auto;
        margin-top: 0;
      }

      .flickity-prev-next-button {
        opacity: 0;
        height: 100%;
        width: 20%;
      }
    }
  }

  .flickity-carousel-story-item {
    height: 100vh;
    width: 100%;

    .story-modal-button-container {
      width: 90%;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);

      @include media-breakpoint-up(sm) {
        width: 80%;
        bottom: 0;
      }
    }

    &.with-transition {
      transition: none;

      @include media-breakpoint-up(sm) {
        transition: padding 250ms linear;
      }
    }

    &:not(.is-selected) {
      opacity: 0.2;
      padding: 0;

      .story-modal-button-container {
        display: none;
      }

      @include media-breakpoint-up(sm) {
        padding: 4rem;
      }
    }

    @media (orientation: landscape) {
      height: 75vh;
      width: 100%;
    }

    @include media-breakpoint-up(sm) {
      height: 75vh;
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      height: 85vh;
      width: 30%;
    }
  }

  .story-modal-link-area {
    text-decoration: none;
  }

  .story-modal-button-arrow {
    @include font-size(4rem);
    line-height: 2rem;
  }
}
