
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-live-events-interaction-modal-component] {

  .live-event-modal {
    .fa-times {
      top: 0;
      right: 0;
    }
    .interaction-modal__content {
      max-width: 600px;
      border-radius: 12px;
      background-color: var(--theme-background);
      color: var(--theme-color);
    }
  }

  @include ie {
    .widget-wrapper {
      min-width: 37.5rem;
    }
  }
}
