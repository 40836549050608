
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.description {
  @include font-size(1.125rem);

  @include media-breakpoint-up(md) {
    @include font-size(1.375rem);
  }
}
