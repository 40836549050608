
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$video-z-index: 9;
$left-content-height: 40vh;

@media (min-width: 2500px) {
	.w-limiter {
		//max-width: 70%;
		margin-left: auto;
		margin-right: auto;

    @media (min-height: 2500px) {
      --inner-height: 60vh;
    }
	}
}

.rounded {
  border-radius: $player-border-radius !important;
}

.player-container {
  --inner-height: calc(var(--vh, 1vh) * 100);
  --header-height: #{$header-height};
  --video-margin: .5rem;
  --player-control-bar-height: #{$player-control-bar-height};
  --player-progress-bar-height: 0px;
  --sidebar-controls-width: 40px;
  --handle-toggle-play-height: 70px;

  &.ondemand-event {
    --player-progress-bar-height: 2rem;
  }

  &.simple-event {
    .ratio-16by9, .ratio-9by16 {
      display: flex;
    }

    .unmute-header {
      position: absolute;
      top: -.5rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 12;

      .tag-unmute {
        cursor: pointer;
        background: $white;
        color: #10111D;
        border-radius: $player-border-radius-sm;
        display: flex;
        font-weight: 700;
        max-height: 24px;
        width: max-content;
        max-width: 180px;
        padding: 6px 8px;

        span {
          font-size: 12px;
          line-height: 13px;
        }
      }
    }

    .play-container {
      height: 100%;
    }
  }

  &.youtube {
    &.paused, &.ended {
      .handle-toggle-play {
        display: block !important;
      }
    }
  }

  .play-container {
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    width: 100%;

    &.ar-9by16 {
      aspect-ratio: 9 / 16;
    }

    &.ar-16by9 {
      aspect-ratio: 16 / 9;
    }

    @include media-breakpoint-down(sm) {
      i { font-size: 4rem; }
    }
  }

  @include media-breakpoint-down(md) {
    --header-height: #{$header-height-sm};

    &:not(.active-right-content) {
      .ratio-9by16 {
        --video-margin: 0px;
      }

      .embed-responsive-9-16, video, iframe {
        border-radius: 0px !important;
      }
    }
  }

  .filter-blur {
    backdrop-filter: blur(5px);
  }

  .gradient {
    top: 0;
    left: 0;
    right: 0;
    z-index: 0;
    height: 15%;
    background-image: linear-gradient(180deg, rgba(16, 17, 29, 0.5) 1%, rgba(16, 17, 29, 0.0001) 100%);
    position: absolute;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .gradient-bottom {
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: $player-border-radius;
    border-bottom-right-radius: $player-border-radius;
    height: calc(var(--player-control-bar-height) + 35px);
    width: 100%;
    background: linear-gradient(0deg, rgba(16, 17, 29, 0.7) 1.2%, rgba(16, 17, 29, 0.0001) 100%);
    transition: all .4s ease-in-out, transform .4s ease-in-out;
  }

  &.fullscreen--fallback {
    position: fixed !important;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1090;
    background-color: $black;
    height: var(--inner-height);
    min-height: var(--inner-height);
    max-height: var(--inner-height);
    flex: 1 1 var(--inner-height);
  }

  &.no-fullscreen {
    .embed-responsive-9by16 {
      max-height: 100%;
      height: calc(100% - var(--header-height));
    }

    .player-overvideo-items {
      padding: var(--video-margin);
    }
  }

  &.fullscreen, &.fullscreen--fallback {
    --header-height: 0px !important;

    .ratio-16by9 {
      height: calc(var(--inner-height) - var(--header-height));
    }

    .player-overvideo-items {
      padding: 0;
    }
  }

  .buffer-loader {
    z-index: calc(#{$video-z-index} + 1);
    width: 100%;
    transition: all .4s ease-in-out, transform .4s ease-in-out;

    i {
      --fa-animation-duration: 1.4s;
    }
  }

  .embed-responsive {
    z-index: $video-z-index;
    transition: all .4s ease-in-out, transform .4s ease-in-out;
  }

  .overlay {
    z-index: 1;
  }

  .ratio-9by16 {
    display: flex;
    justify-content: center;
    height: calc(var(--inner-height) - var(--header-height));

    .embed-responsive-9by16 {
      // NB this suppose the video with full height
      width: calc((var(--inner-height) - var(--video-margin)*2 - var(--header-height)) * 9 / 16);

      ::v-deep {
        iframe, video {
          object-fit: cover;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100vw;
      }
    }

    .player-overvideo-items {
      display: flex;
      justify-content: center;

      .main-content {
        position: relative;
        width: calc((var(--inner-height) - var(--video-margin)*2 - var(--header-height)) * 9 / 16);
        height: calc(100% - var(--video-margin)*2 - var(--header-height));
        margin-top: var(--video-margin);
        z-index: calc(#{$video-z-index} + 1);
        transition: all .4s ease-in-out, transform .4s ease-in-out;
      }

      .left-content, .right-content {
        flex: 1;
        position: relative;
        max-width: calc((100vh - var(--header-height)) * 9 / 16);

        @include media-breakpoint-up(lg) {
          .content-container {
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;

            &.left, &.right {
              opacity: 0;
            }
          }
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100vw;

        .main-content {
          width: 100%;
        }

        .left-content, .right-content {
          max-width: 100%;
        }
      }
    }
  }

  .ratio-16by9.active-right-content {
    .embed-responsive-container {
      width: 100% !important;
    }

    .embed-responsive, .main-content, .buffer-loader {
      width: calc(100% / 3 * 2) !important;
      transform: translateX(0px);

      @media (orientation: landscape) {
        @include media-breakpoint-down(lg) {
          width: 60% !important;
        }
      }

      @media (orientation: portrait) {
        @include media-breakpoint-down(lg) {
          width: 100% !important;
        }
      }
    }
  }

  .ratio-9by16.active-right-content {
    .embed-responsive {
      display: flex;
      justify-content: center;

      ::v-deep video, iframe {
        position: relative;
        width: auto;
        border-radius: 10px;
        object-fit: contain;
      }
    }

    @include media-breakpoint-down(md) {
      height: calc(var(--inner-height) - var(--header-height));

      .embed-responsive, .main-content {
        margin-top: var(--video-margin);
        height: calc(100vw * 9 / 16) !important; // to full view 16:9
        width: 100vw;
        transition: all .4s ease-in-out, transform .4s ease-in-out;
      }

      .main-content {
        .gradient-bottom, .player-side {
          display: none;
        }
      }
    }
  }

  .ratio-9by16.active-left-content {
    .gradient-bottom {
      @include media-breakpoint-down(sm) {
        height: calc(var(--player-control-bar-height) + 40vh);
      }
    }
  }

  .ratio-16by9 {
    height: auto;
    width: auto !important;
    margin: auto;
    max-width: 100%;
    padding: .5rem !important;

    .embed-responsive-container {
      width: Min(calc((var(--inner-height) - var(--header-height) - var(--video-margin)*2) * 16 / 9), 100%);
      transition: width .4s ease-in-out, transform .4s ease-in-out, left 0s ease-in-out;
      display: flex;
      top: 0;
      right: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .embed-responsive {
      @media (orientation: portrait) {
        width: 100%;
        left: 0% !important;
        transform: translateX(0%);
      }

      ::v-deep {
        iframe, video {
          border-radius: $player-border-radius;
        }
      }
    }

    @include media-breakpoint-down(md) {
      .embed-responsive video {
        height: auto !important;
      }
    }

    .player-overvideo-items {
      left: 0;
      right: 0;
      display: flex;
      z-index: calc(#{$video-z-index} + 2);

      .main-content {
        position: relative;
        width: 100%;
        height: 100%;
        transition: all .4s ease-in-out, transform .4s ease-in-out;
      }

      .left-content, .right-content {
        width: calc(100% / 3);

        @include media-breakpoint-between(md, lg) {
          width: 40%;
        }
      }
    }

    @media (orientation: landscape) {
      --video-margin: .5rem;

      .player-overvideo-items {
        transition: width .4s ease-in-out, transform .4s ease-in-out;
        padding: var(--video-margin);
        width: Min(calc((var(--inner-height) - var(--header-height) + 1px - var(--video-margin)) * 16 / 9), 100%);
        left: 50%;
        transform: translateX(-50%);
        
        .left-content {
          position: absolute;
          bottom: calc(var(--player-control-bar-height) + var(--player-progress-bar-height) + var(--video-margin) + 10px);
          height: 50%;
          
          .content-container {
            display: none;
          }

          &.active {
            z-index: 1;
            .content-container {
              display: block;
            }
          }

          &.active + .main-content {
            background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 71.86%, rgba(0, 0, 0, 0.5) 100%);
          }
        }

        .right-content {
          position: absolute;
          top: 0;
          bottom: 0;
          right: -100%;
          transition: right .4s ease-in-out, transform .4s ease-in-out;

          &.active {
            right: 0;
          }

          .content-container {
            opacity: 0;
            padding: .5rem !important;
          }
        }

        @include media-breakpoint-down(md) {
          .left-content, .right-content {
            width: 40%;
          }
        }

        &.active-right-content {
          width: 100%;
        }
      }
    }

    @media (orientation: portrait) {
      &.active-right-content {
        @include media-breakpoint-down(md) {
          .embed-responsive, .main-content, .buffer-loader {
            width: 100% !important;
            height: auto !important;
          }
        }
      }

      .player-overvideo-items {
        .left-content {
          transition: all .4s ease-in-out, transform .4s ease-in-out;
          position: absolute;
          width: calc(100% - var(--sidebar-controls-width) - 1rem);
          bottom: calc(var(--player-control-bar-height) + var(--player-progress-bar-height));
          top: calc(100vw * 9 / 16);

          &.has-banners {
            top: calc((100vw * 9 / 16) + 1rem + 48px);
          }

          .content-container {
            display: none;
          }

          &.active {
            z-index: 11;
            .content-container {
              display: block;
            }
          }
        }

        .right-content {
          position: absolute;
          bottom: -100vh;
          top: 100%;
          left: 0;
          width: 100vw;
          height: auto;
          transition: all .4s ease-in-out, transform .4s ease-in-out;
          z-index: 12;

          &.active {
            bottom: 0;
            top: calc((100vw * 9 / 16) + var(--video-margin)*2);
            height: auto;
          }
        }
      }
    }
  }
  
  .ratio-9by16{
    .player-overvideo-items {
      @include media-breakpoint-down(md) {
        .left-content {
          position: absolute;
          width: calc(100% - var(--sidebar-controls-width) - 1rem);
          left: 0;
          bottom: calc(var(--player-control-bar-height) + var(--player-progress-bar-height));
          height: $left-content-height;

          .content-container {
            display: none;
          }

          &.active {
            z-index: 11;
            .content-container {
              display: block;
            }
          }
        }

        .right-content {
          position: absolute;
          bottom: -100vh;
          top: 100%;
          width: 100vw;
          height: auto;
          transition: all .4s ease-in-out, transform .4s ease-in-out;
          z-index: 12;

          &.active {
            bottom: 0;
            top: calc((100vw * 9 / 16) + var(--video-margin)*2);
            height: auto;
          }
        }
      }
    }
  }

  .player-overvideo-items {
    position: absolute;
    top: 0;
    bottom: 0;

    @include media-breakpoint-up(lg) {
      right: 0;
      left: 0;
    }

    .player-settings {
      position: absolute;
      bottom: var(--player-control-bar-height);
      right: 0;
      z-index: 16;
    }

    .sub-header {
      display: block;
      position: absolute;
      top: 42px;
      width: 100%;
      z-index: 14;
    }

    .handle-toggle-play {
      height: var(--handle-toggle-play-height); 
      width: 100%;
      position: absolute;
      top: calc(50% - var(--handle-toggle-play-height) / 2);
      display: none;
    }

    .overvideo-interaction, .player-end-content {
      z-index: 11;
      overflow-y: scroll; 

      ::v-deep .themed-item {
        background-color: transparent !important;
      }
    }
  }

  &.event-shopping {
    .ratio-16by9 {
      @media (orientation: portrait) {
        @include media-breakpoint-down(md) {
          .right-content.active {
            top: calc((100vw * 9 / 16) + var(--video-margin)) !important;
          }

          .sub-header {
            top: calc((100vw * 9 / 16) + .5rem);
          }
        }
      }
    }
  }

  .player {
    &-bottom {
      position: absolute;
      bottom: 0;
      height: var(--player-control-bar-height);
      width: 100%;
      transition: opacity .4s ease-in-out, transform .4s ease-in-out;
    }

    &-side {
      position: absolute;
      //bottom: var(--player-control-bar-height);
      bottom: calc(var(--player-control-bar-height) + var(--player-progress-bar-height) + var(--video-margin));
      right: 0;
      font-size: 1.3rem;
      display: flex;
      flex-direction: column;
      padding-left: 14px;
      z-index: 15;
    }
  }

  &.show-controls {
    .player-bottom {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:not(.show-controls) {
    .gradient-bottom {
      height: 0;
    }

    .player-bottom {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  @include media-breakpoint-down(sm) {
    &.active-right-content {
      .player-bottom, .left-content {
        opacity: 0;
        transform: translateY(100%); 
      }
    }
  }
}

.rotate-container {
  background: $black;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: none;

  @include media-breakpoint-down(md) {
    @media (orientation: landscape) and (max-height: 414px) {
      display: flex;
    }
  }

  .phone {
    height: 50px;
    width: 100px;
    border: 3px solid $white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
  }

  .message {
    color: $white;
    font-size: 1em;
    margin-top: 40px;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg)
    }
    50% {
      transform: rotate(-90deg)
    }
    100% {
      transform: rotate(-90deg)
    }
  }
}
