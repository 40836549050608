
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-quote-component] { 

  a {
    text-decoration: none;
    
    @include hover {
      text-decoration: none
    }
  }

  .social-icons {
    @include font-size(1.25rem);
  }

  .author {
    font-family: $neutra-text-font;
    @include font-size(1.125rem);
  }

  .description {
    @include font-size(1.875rem);
  }

  .avatar {
    width: 7.5rem;
    height: 7.5rem;
  }

  .role {
    font-family: $neutra-text-font;
    @include font-size(.875rem);
  }

  @include media-breakpoint-down(xs) {
    .social-icon {
      @include font-size(1.75rem);
    }
  }
}
