
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-preview-component] {
  .preview {
    height: 12rem;
  }

  .preview-gradient {
    background-image: linear-gradient(to bottom, rgba($white, 0), $white);
  }

  .locked-content-description {
    a {
      color: #ffffff !important;
    }
  }

  .icon {
    width: 4rem;
    height: 4rem;
  }
}
