
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.icon {
  transform: rotate(-45deg);
  transition: transform .125s ease-in-out;

  .collapsed & {
    transform: rotate(0);
  }
}

.description {
  @include font-size($font-size-base * .95);

 ::v-deep p {
    margin-bottom: 0;
  }
}
