
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

@mixin isotope-square($size, $spacer) {
  width: calc(#{$size} - #{$spacer} * 2);
  height: 0;
  padding-bottom: calc(#{$size} - #{$spacer} * 2);
}

@mixin isotope-vertical-rectangle($size, $spacer) {
  width: calc(#{$size} - #{$spacer} * 2);
  height: 0;
  padding-bottom: calc(#{$size * 2} - #{$spacer} * 2);
}

@mixin isotope-horizontal-rectangle($size, $spacer) {
  width: calc(#{$size * 2} - #{$spacer} * 2);
  height: 0;
  padding-bottom: calc(#{$size} - #{$spacer} * 2);
}

@mixin isotope-horizontal-rectangle-mobile($size, $spacer) {
  width: calc(100% - #{$spacer} * 2);
  height: 0;
  padding-bottom: calc(#{$size} - #{$spacer} * 2);
}

[isotope-grid] {
  $spacer: .5rem;

  &.dark-theme {
    background-color: $dark;
    color: $white;

    .flickity-page-dots .dot {
      background-color: $white;
    }
  }

  .isotope-box {
    margin: $spacer;
    @include isotope-square(25%, $spacer);

    &.grid-item--widecontent {
      @include isotope-horizontal-rectangle(25%, $spacer);
    }

    &.grid-item--largesquarecontent {
      @include isotope-square(50%, $spacer);
    }

    &.grid-item--highcontent {
      @include isotope-vertical-rectangle(25%, $spacer);
    }
  }

  @include media-breakpoint-down(xs) {
    .isotope-box {
      @include isotope-square(100%, $spacer);

      &.grid-item--widecontent {
        @include isotope-horizontal-rectangle-mobile(60%, $spacer);
      }

      &.grid-item--largesquarecontent {
        @include isotope-square(100%, $spacer);
      }

      &.grid-item--highcontent {
        @include isotope-square(100%, $spacer);
      }
    }
  }
}

