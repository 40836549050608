
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.avatar {
  width: 13.125rem;
  height: 13.125rem;
  object-fit: cover;
}

.icon {
  color: $black;
  transition: color .5s ease-in-out;

  @include hover {
    color: $red;
  }
}
