
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-filter-component] {
  .layer, .filter-menu {
    overflow: auto;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    bottom: 0;
    left: 0;
  }

  .layer {
    background-color: rgba(0,0,0,0.5);
  }

  .filter-menu {
    width: 50rem;
    max-width: calc(100vw - 3.5rem);
  }

  .layer {
    .close-icon {
      width: 3.5rem;
      height: 3.5rem;
      top: 0;
      right: 0;
      color: white;
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: color .25s;

      & > i {
        line-height: 1.5rem;
        @include font-size(1.5rem);
      }

      &:hover {
        color: $gray-300;
      }
    }
  }
}
