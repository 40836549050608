
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$icon-size: 2.625rem;

.main-img {
  padding-bottom: 57.6%;
}

.pizzeria-main-image {
  object-fit: cover;
  top: 0;
  left: 0;
}

.category-wrapper {
  line-height: 1;
}

.owner-avatar {
  height: 4rem;
  width: 4rem;
}

.quote-avatar {
  width: 6rem;
  height: 6rem;
}

.quote-description {
  @include font-size(1.375rem);
  line-height: 1.45;
}
