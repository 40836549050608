
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  


.content-wrapper {
  z-index: 5;
}

.over-video-content {
  height: 100vh;
  //min-height: 800px;
  z-index: 5;
}

.logo {
  max-width: 200px;
  max-height: 200px;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

@include media-breakpoint-down(sm) {
  .over-video-content {
    height: calc(100vh - 6.25rem);
  }
}



