
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[header] {

  $header-logo-width: 7.575rem;
  $header-logo-height: 6.875rem;

  z-index: $zindex-menu-item + 2;

  @include media-breakpoint-up(md) {
    min-height: $header-logo-height * 1.5;
  }

  .logo-container {
    width: $header-logo-width;
    height: $header-logo-height * 0.6;

    @include media-breakpoint-up(md) {
      height: $header-logo-height;
    }
  }

  .logo {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .profile-menu {
    top: calc(100% + .25rem);
    right: 0;
    min-width: 8.5rem;
  }

  [aria-expanded="true"] {
    .fa-chevron-down {
      transform: rotate(180deg);
    }
  }

  .hamburger {
    @include font-size(1.25rem);
    line-height: 1rem;
  }

  .search {
    z-index: 1;
  }

  .fal.fa-times,
  .far.fa-search {
    @include font-size(1.25rem);
  }

  .search-stripe {
    z-index: $zindex-menu-item + 2;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      left: -1.5rem;
      top: -5rem;
      width: 100vw;
      max-width: map-get($container-max-widths, "md") - $grid-gutter-width;
    }

    @include media-breakpoint-up(lg) {
      max-width: map-get($container-max-widths, "lg") - $grid-gutter-width * 1.5;
    }

    @include media-breakpoint-up(xl) {
      max-width: map-get($container-max-widths, "xl") - $grid-gutter-width * 2;
    }
  }

  .search-input {
    box-shadow: 0 0 .625rem 0 rgba($black, 0.1);
    border-radius: .625rem;
    overflow: hidden;

    .form-control {
      height: 2.2rem;
      @include font-size(1rem);

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .far.fa-search {
      @include font-size(1.125rem);
    }
  }

  @include media-breakpoint-down(sm) {
    .hamburger {
      display: block !important;
    }

    .search > div {
      display: none !important;
    }
  }

}

[layer-dark] {
  background-color: rgba($black, .35);
  z-index: 1;
  top: 0;
}

[layer-light] {
  background-color: rgba($white, .5);
  z-index: $zindex-menu-item + 1;
  top: 0;
}
