
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[generic-form-component] {
  .v-autocomplete-input-group {
    width: 100%;
    height: 100%;

    .v-autocomplete-input {
      border: none;
      box-shadow: none;
      width: 100%;
      height: 100%;
      outline: none !important;
    }
  }

  .v-autocomplete-list {
    top: 4.0625rem;
    left: 0;
    right: 0;
    outline: 1px solid $gray-300;
    z-index: $zindex-dropdown;

    .v-autocomplete-list-item {
      background-color: $white;
    }
  }
}
