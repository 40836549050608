
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.active {
  background-color: $red;
}
input[type=checkbox]:checked + label {
  @extend .active;
}
.form-icon {
  width: 1.5rem;
  height: auto;
}
.dropdown-menu {
  margin-top: 10px;
  width: 100%;
}
.custom-checkbox-checkbox {
  position: absolute;
  opacity: 0;
  z-index: -1;
  bottom: 0;
}
