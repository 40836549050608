
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

video {
  object-fit: cover;
}

.btn.btn-lg {
  margin: 0 1rem;
  font-size: 3rem;
  width: 200px;
  height: 200px;
  text-transform: uppercase;
  border-radius: 50%;
}
