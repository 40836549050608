
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-carousel-products-component] {

  .flickity-page-dots .dot {
    width: 15px !important;
    height: 15px !important;
    margin: 0 6px !important;
  }

  .carousel-product-title {
    font-size: 40px;
  }

  .flickity-carousel-products-item {
    width: 33.33334%;

    .slide-image-container {
      width: 65%;
      padding-bottom: 65%;
      position: relative;

      .slide-image {
        top: 0;
        width: 100%;
        position: absolute;
        padding-bottom: 100%;
        transition: all .5s;
        filter: opacity(100%);
      }
    }
    
    .procuct-item__title {
      padding-top: 0.8rem !important;
      font-size: 40px;
    }
    
    .procuct-item__title {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
    }
    .btn,
    .procuct-item__title,
    .product-item__description {
      transition: opacity .5s;
      opacity: 1;
    }
    .product-item__description {
      color: #575756;
      padding: 10px 0;
      border-top: 1px solid #575756;
      border-bottom: 1px solid #575756;

      & strong {
        display: block;
        margin-top: 1rem;
      }

      & > p:first-child {
        //margin-bottom: 0;
        line-height: 40px;
      }
    }
  }

  .flickity-carousel-products-item:not(.is-selected) {
    // height: 100%;
    .slide-image-container {
      .slide-image {
        width: 70%;
        padding-bottom: 70%;
        filter: opacity(50%);
      }
    }
    .btn,
    .procuct-item__title,
    .product-item__description {
      opacity: 0;
    }
  }

  .flickity-button {
    transform: scale(2);
  }
  .flickity-button,
  .flickity-button:hover {
    background-color: transparent !important;
  }

  .carousel-anchor {
    width: 7rem;
    .anchor-point {
      width: 2rem;
      height: 2rem;
      position: relative;
    }
    .anchor-title {
      font-size: .96rem;
    }
    &.active {
      .anchor-point::after {
        content: '';
        position: absolute;
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;
        border-radius: 100%;
        border: 1px solid $black;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .carousel-product-title {
      font-size: 30px;
    }
    .flickity-carousel-products-item {
      width: 80%;
    }
    .flickity-button {
      transform: scale(1.25);
    }
    .procuct-item__title {
      font-size: 34px !important;
    }
    .product-item__description {
      font-size: 20px !important;
    }
    .carousel-anchor {
      width: 3.75rem;
      .anchor-point {
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
      }
      .anchor-title {
        font-size: .8rem;
      }
    }
    .flickity-prev-next-button.previous {
      left: -1.25rem;
    }
    .flickity-prev-next-button.next {
      right: -1.25rem;
    } 
  }
}
