
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$icon-size: 2.625rem;

.content-url {
  z-index: 1;
}

.thumbnail {
  min-height: 12.125rem;

  @include media-breakpoint-up(md) {
    min-height: 16.5rem;
  }
}

.avatar {
  width: $icon-size;
  height: $icon-size;
}

.owner-title {
  @include font-size(1rem);
}
