// sign_in and sign_up pages

$google-red: #EA4335;
$google-blue: #4285F4;
$google-green: #34A853;
$google-orange: #FBBC05;

.facebook-svg-icon {
  fill: #FFF;
}

.google-svg-icon {
  fill: none;
  fill-rule: evenodd;
}

.gs-1 {
  fill: $google-blue;
}

.gs-2 {
  fill: $google-green;
}

.gs-3 {
  fill: $google-orange;
}

.gs-4 {
  fill: $google-red;
}

[registration-pages] {
  small {
    @include font-size(.925rem);
  }

  .btn-google {
    color: $black;
    border: 1px solid #ccc;
    background-color: $white;

    &:hover,
    &:active,
    &:focus {
      background-color: $google-blue;
      border-color: $google-blue;
      color: $white;

      .btn-google__logo-container {
        background-color: $white;
      }

      .google-svg-icon {
        background-color: $google-blue;
      }

      .gs-1 {
        fill: darken($google-blue, 25%);
      }
    }
  }

  .btn-facebook,
  .btn-google {
    padding-right: 0;
    width: 15.875rem;
    height: 2.5rem;
    font-family: Helvetica, Arial, sans-serif;
    overflow: hidden;
    display: flex;
    border-radius: .25rem;
    text-decoration: none !important;

    .btn-facebook__logo-container {
      height: 1.5rem;
      margin: .5rem;
      width: 1.5rem;
      display: block;
    }

    .btn-google__logo-container {
      &:hover {
        background-color: $white;
      }
      svg {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .btn-facebook__text-container,
    .btn-google__text-container {
      width: 13.4375rem;
      padding: .5rem;
      @include font-size(1rem);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .btn-facebook {
    fill: $white;

    &:hover,
    &:focus,
    &:active {
      fill: $facebook-blue;
    }
  }

  .hr-separator {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba($black, 0.35);
    margin: 8px 0px;
  }
  .hr-separator::before,
  .hr-separator::after {
    content: "";
    flex-grow: 1;
    background-color: $medium-grey;
    opacity: 0.5;
    height: 1px;
    @include font-size(0px);
    line-height: 0px;
    margin: 0px 8px;
  }

  .header-image {
    background-position: center;
    background-size: cover;
    min-height: 245px;
  }

  .form-control:not(textarea) {
    height: 4rem;
  }

  .registration-footer {
    .icon {
      img {
        margin: 0 auto;
        max-width: 100%;
        display: block;
      }
    }

    .invite-registration-modal__header {
      padding-top: 2rem;
      font-family: $italia-lt-font;
    }
    
    .invite-registration-modal__separator {
      height: 1px;
      background-color: #000;
      margin: 2rem 0;
      position: relative;
      span {
        padding: 0 1rem;
        background-color: #fbbfa3;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        font-family: $neutra-text-font;
        @include font-size(.75rem);
      }
    }
    
    .invite-registration-modal__content-element__title p,
    .invite-registration-modal__content-element__description p {
      text-align: center;
    }
    
    .invite-registration-modal__content-element__title p {
      @include font-size(1.375rem); /* 22/16 */
      font-family: $italia-lt-font;
    }
    
    .invite-registration-modal__content-element__description p {
      @include font-size(0.875rem);
    }
  }
}
