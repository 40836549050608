
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.payment-form {
  align-self: center;

  &-content {
    background-color: var(--theme-background);
    color: var(--theme-color);
    border: 2px solid $black;
  }

  label {
    margin-bottom: 0;
    -webkit-font-smoothing: antialiased;
  }
  
  input {
    line-height: 20px;
    padding: 12px
  }

  .amount-input {
    flex: 1;
  }

  .amount-preselection {
    &-container {
      flex-wrap: wrap;
      gap: 10px;

      .btn-amount-preselection {
        flex: 1;
        min-width: 70px;
      }
    }
  }

  .purchase-btn {
    color: #ffffff;
    border-radius: 4px;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;

    &:hover {
      filter: contrast(115%);
    }

    &:disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
