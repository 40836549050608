[sidebar-component] {
  z-index: $zindex-menu-item + 2;
  top: 0;
  width: 70vw;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include media-breakpoint-up(md) {
    width: 20vw;
  }

  .close-icon {
    z-index: 3;
    @include font-size(1.5rem);
    opacity: .85;
    line-height: .85rem;
    right: 1rem;
    top: .75rem;
  }

  a {
    text-decoration: none !important;
    font-size: 1rem;
  }
}

.lang-icon {
  width: 1.375rem;
  height: 1.375rem;
}
