
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$icon-size: 3.5rem;

.tile-url {
  @include hover {
    text-decoration: none;
  }
}

.category {
  line-height: 1;
  top: 1rem;
  left: 1rem;
}

.subtitle {
  @include font-size(1.25rem);
  @include media-breakpoint-down(xs) {
    line-height: 0.5;
  }
}

.title-wrapper {
  bottom: 1rem;
  left: 1rem;
}

.dark-layer {
  background-color: rgba($black, .3);
}

.icon-container {
  @include font-size($icon-size / 2);
  width: $icon-size;
  height: $icon-size;
  border-width: 2px !important;
}
