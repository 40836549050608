
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-component] {
  color: $white;

  [reward-marker-component] {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .tile-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .tile-title {
      @include font-size(1rem);
    }

    .tile-footer__user-info {
      display: flex;
      align-items: center;
      margin: 1rem 0;
    }

    .tile-footer__user-info__avatar {
      max-width: 40px;
      border-radius: 100%;
      border: 2px solid gray;
      margin-right: 1rem;
    }

    .tile-footer__user-info__name {
      margin: 0;
      padding: 0;
    }
  }
}
