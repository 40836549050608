
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.share-icon {
  border-radius: 100%;
  height: 2rem;

  .fa-lg {
    @include font-size(1rem);
  }
}
