
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-search-component] {

  .no-results {
    min-height: 35vh;
  }

  .thumbnail {
    height: 6.25rem;
    width: 9.375rem;
  }
}
