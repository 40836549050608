
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[modal-user-group-required-component] {

  .close-icon {
    font-family: $italia-lt-font;
  }

  [generic-form-component]::v-deep {
    color: $dark;

    .form-group {
      text-align: left;
    }
  }
}
