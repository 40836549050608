
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.synthesizer {
  height: 150px;
  width: 600px;
  max-width: 100%;

  > div {
    height: 30px;
    min-width: 50px;
    transition: width .05s linear;
    box-shadow: 0px 0px 10px $white;
  }
}

.overlay-texture {
  position: absolute;
  background-size: cover;
  background-position: center;
  inset: 0;
}
