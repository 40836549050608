
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[navbar-user-component] {

  .navbar-user-notices-counter {
    width: 1rem;
    height: 1rem;
    background-color: $red;
    color: $white;
    border-radius: 100%;
    @include font-size(0.5rem);
    position: absolute;
    left: 0;
    top: 0;
  }

  .navbar-user-marker {
    border-radius: 2rem;
    min-width: 5rem;
    z-index: 10;
    position: relative;
    white-space: nowrap;

    background-color: $primary;
    color: $white;
  }

  .navbar-user-avatar {
    position: relative;
    width: 3rem; 
    height: 3rem; 
    border-radius: 100%;
    background-color: $gray-300;
    @include font-size(1.5rem);
  }
}
