$easyadmin-marker-zindex: 9999;

.easyadmin-marker {
  display: none;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}

.easyadmin-mode {

  ._content {
    border: 1px solid $primary !important;
    margin: 0.5rem;

    &:not(.position-absolute-center) {
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
    }

    > .easyadmin-marker {
      width: 2rem;
      height: 2rem;
      background-color: $secondary;
      border: 1px solid $white;
      border-radius: 100%;
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      text-decoration: none !important; // href override
      color: $white !important; // href override
      z-index: $easyadmin-marker-zindex;
      opacity: 1;
      pointer-events: all;
      visibility: visible;
      font-size: .875rem;

      &:hover {
        z-index: $easyadmin-marker-zindex + 1;
      }
    }

    &._content-record > .easyadmin-marker {
      background-color: $primary;
    }
  }

  .easyadmin-main-buttons-editing {
    visibility: visible;
    opacity: 1;
  }

}

.btn-easyadmin-inline-editing {
  width: 2rem;
  height: 2rem;
  background-color: $white;
  border: 1px solid $primary;
  border-radius: 100%;
  position: fixed;
  left: 0.25rem;
  bottom: 0.25rem;
  z-index: $easyadmin-marker-zindex + 1;
  text-decoration: none !important; // href override
  color: $primary !important; // href override
  font-size: 1rem;
}

.easyadmin-main-buttons-editing {
  position: fixed;
  left: 0.25rem;
  bottom: 2.5rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms, visibility 300ms;
  -webkit-transition: opacity 300ms, visibility 300ms;
  z-index: $easyadmin-marker-zindex + 1;

  .easyadmin-main-button {
    position: relative;
    margin-top: 0.25rem;
    width: 2rem;
    height: 2rem;
    background-color: $white;
    border: 1px solid $primary;
    border-radius: 100%;
    text-decoration: none !important; // href override
    color: $primary !important; // href override
    font-size: 1.25rem;
  }
}
