.error-pages-title {
  @include font-size(3rem);
  font-weight: bold;
}

.error-pages-description {
  @include font-size(2rem);
  font-weight: 300;
}

.error-pages-title,
.error-pages-description {
  color: $white;
  text-shadow: 0 0 1px $black;
}

.buorg {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($black, .45) !important;
  font-family: $font-family-base;
}

.buorg-pad {
  width: 80%;
  padding: 4rem !important;
  display: block;
  background-color: $white;
}

.buorg-mainmsg {
  @include font-size(2rem);
}

.buorg-moremsg {
  display: block;
}

.buorg-buttons #buorgul {
  @extend .btn;
  @extend .btn-primary;
  margin-top: 1rem;
}
