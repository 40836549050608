
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-favourite-component] {

  [tile] {
    position: relative;
  }

  h1 {
    color: $primary;
  }

  h3, p {
    margin: 0;
  }

  i {
    @include font-size(1.25rem);
    display: block;
  }

  ul {
    padding-inline-start: $list-inline-padding;
  }

  li > p {
    line-height: 22px;
  }

  li, i {
    cursor: pointer;
    list-style-type: none;
    transition: color .25s ease-in-out;

    @include hover {
      color: darken($primary, 5%);
    }
  }

}
