
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.ar-16by9 {
  @include media-breakpoint-down(sm) {
    padding-bottom: 100%;
  }
}

.table-row {
  height: 1.125rem;
}

.place-icon {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
}

.font-family-italia-lt-book {
  font-family: "Italia LT Book", Georgia, Times, serif;
}

.font-family-neutra-display {
  font-family: "Neutra Display", Helvetica, Arial, sans-serif;
}
