$formats: ("woff2": "woff2", "woff": "woff");

// Used to select the thumb of a slider
@mixin thumb-styles($selector: "&") {
  #{$selector}::-webkit-slider-thumb {
    @content
  }
  #{$selector}::-moz-range-thumb {
    @content
  }
  #{$selector}::-ms-thumb {
    @content
  }
}

// Select only screens with a pixel density of ~ 300 PPI ("Retina display" and other similar screens)
@mixin hidpi {
  @media screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 2dppx) {
    @content;
  }
}

// Used to select the placeholder of an element (eg, placeholder text in an input)
@mixin placeholder {
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @content
  }
  &:-ms-input-placeholder { /* IE 10+ */
    @content
  }
  &.placeholder {
    @content
  }
}

// Changes the font smoothing technique to antialias at pixel level instead of sub pixel
// Will made the fonts lighter on hidpi screens and blocky on low res devices, so be careful
@mixin anti-alias-font {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

// Generates the font-face for a font
@mixin gen-font-face($family, $src, $weight, $style, $display) {
  @font-face {
    src: $src;
    font-family: $family;
    font-weight: $weight;
    font-style: $style;
    font-display: $display;
  }
}

// Helper mixin to generate the font-face and searching the file in the base assets directory
@mixin fandom-font($family, $font, $weight: normal, $style: normal, $display: auto) {
  $src: null;
  @each $format, $mime in $formats {
    $src: append($src, url("~src/fonts/#{$font}.#{$mime}") format("#{$format}"), comma);
  }
  @include gen-font-face($family, $src, $weight, $style, $display);
}

// Helper mixin to generate the font-face and searching the file in the tenant's assets directory
@mixin tenant-font($family, $font, $weight: normal, $style: normal, $display: auto) {
  $src: null;
  @each $format, $mime in $formats {
    $src: append($src, url("../fonts/#{$font}.#{$mime}") format("#{$format}"), comma);
  }
  @include gen-font-face($family, $src, $weight, $style, $display);
}

// Helper mixin to apply styles only to internet explorer
@mixin ie {
  @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}
