
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.badge {
  background-color: $gray-200;
  font-size: 1rem;
  font-weight: 300;
  
  &-up {
    color: $success;
  }

  &-down {
    color: $danger;
  }

  &-new {
    color: $info;
  }
}
