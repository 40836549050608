
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[one-col-component] {

  /*TODO: to remove after fixed in variables file*/
  $secondary-nav-height: 48px;

  .dark-theme {
    background-color: $dark;
    color: $white;
  }

  .anchor{
    display: block;
    height: calc(#{$header-height-sm} + #{$secondary-nav-height});
    margin-top:  calc(-#{$header-height-sm} - #{$secondary-nav-height});
    visibility: hidden;
  }

  [text-section-component] ::v-deep a.btn {
    padding: 1rem 2rem !important;
  }
}
