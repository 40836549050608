
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[course-sidebar-component] {

  .course-sidebar__header {
    color: $white;

    h3 {
      @include font-size($font-size-base);
    }

    .course-sidebar__close {
      color: $white;
    }
  }

  .course-sidebar__item-icon {
    i { color: $white; }
  }

  .course-index__item__active {
    background-color: $white !important;
    a { color: $black !important; }
  }

  .course-index__item {
    text-transform: none;
    color: $white;
    font-weight: bold;
    @include font-size($font-size-base);
    
    .far.fa-chevron-up {
      transition: transform .25s;
    }

    &.collapsed .far.fa-chevron-up {
      transform: rotate(180deg);
    }

    &.secondary {
      font-weight: normal;
    }
  }

  .card {
    border-radius: 0;
    border-right: none;
    border-color: $dark;
  }

  .card-header {
    border-bottom: none;
    border-radius: 0 !important;
  }

  .card:not(:first-child) {
    border-top: none;
  }

}
