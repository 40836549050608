
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-popup-component] {
  color: $white;

  .category {
    @include font-size(.75rem);
  }

  [reward-marker-component] {
    position: absolute;
    top: 0;
    right: 0;
  }

  .tile-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .tile-footer-img {
      width: 5rem;
    }
  }
}
