
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[list-keypoint-component] {

  .keypoint {
    width: 13.625rem;
    color: $dark;
    transition: color .5s ease-in-out;

    @include media-breakpoint-down(xs) {
      width: 35%;
    }
  }

  .flickity-viewport {
    overflow: visible;
  }


   /* force on extra large screens to have max 5 points in a row */
  @media only screen and (min-width: 1800px) {
    .keypoint {
      width: calc(20% - 4rem);
    }
  }
}

