.modal-content {
  border: none;
  border-radius: 0;
  background-clip: border-box;
}

.modal-content {
  .close {
    position: absolute;
    right: 1rem;
    top: 0.85rem;
    @include font-size(1.85rem);
    color: $black;
    opacity: 1;
    z-index: 10;
  }
}

.btn {
  text-transform: uppercase;
  overflow-anchor: none;

  @include media-breakpoint-up(md) {
    @include button-size(
      $btn-md-multiplier * $btn-padding-y,
      $btn-md-multiplier * $btn-padding-x,
      $btn-md-multiplier * $btn-font-size,
      $btn-md-multiplier * $btn-line-height,
      $btn-md-multiplier * $btn-border-radius
    );
  }
}

.col-xs-lnd-6 {
  @include media-breakpoint-down(xs) {
    @media (orientation: landscape) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.v-autocomplete-input-group {
  width: 100%;
  height: 100%;

  .v-autocomplete-input {
    border: none;
    box-shadow: none;
    width: 100%;
    height: 100%;
    outline: none !important;
  }
}

.v-autocomplete-list {
  top: 4.0625rem;
  left: 0;
  right: 0;
  outline: 1px solid $gray-300;
  z-index: $zindex-dropdown;

  .v-autocomplete-list-item {
    background-color: $white;
  }
}
