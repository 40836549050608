
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.form-control {
  border-color: $gray-400;
  box-shadow: none;
}

.close,
.form-control {
  outline-color: transparent !important;
  outline-style: none !important;
}
