
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[properties-component] {
  background-color: $user-dashboard-bg-color;
  
  .header-around {
    width: 160px;

    .header-logo {
      height: 80px;
    }
  }

  .flickity-page-dots {
    display: none;
  }

  .flickity-prev-next-button {
    background-color: transparent;

    &.previous {
      left: -50px;
    }

    &.next {
      right: -50px;
    }
  }

  .flickity-carousel-item {
    width: 50%;
  }
}
