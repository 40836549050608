
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[tile-ugc-box-pizza-component] {
  $img-padding: 67.5%;
  $avatar-size: 25%;
  $avatar-img-cover-size: 90%;

  .ugc-img-wrapper {
    padding-bottom: $img-padding;
  }

  .ugc-img-content {
    background-color: $light-grey;
  }
}
