
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.flickity-carousel-item {
  .flickity-carousel & {
    width: calc(100% - 3rem);
    margin: 1.5rem;

    @include media-breakpoint-up(md) {
      width: 45%;
      margin: 0 .5rem;
    }
  }

  .d-flex & {
    position: relative;
    flex: 1;
  }

  &.first-child-margin-right:first-child {
    margin-right: 1rem;
  }
}
