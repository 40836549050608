
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[stripe-newsletter] {
  min-height: 22.875rem;

  .input-checkbox {
    opacity: 0;
    width: 1px;
    position: relative;
    left: -18px;
    top: 11px;
  }

  .newsletter-icon {
    max-width: 4.15rem;
    max-height: 4.15rem;
  }

  .newsletter-description {
    @include font-size(1rem);
  }

  .custom-input {
    height: 4rem;
    border: 0;
    padding-left: 1.5rem;
    border-radius: 1rem;

    @include placeholder {
      color: $medium-grey;
    }

    &:hover, &:focus, &:active {
      outline: none;
    }
  }

  .show-newsletter-privacy-modal {
    color: inherit;
    font-weight: 700;
  }

  .small-text {
    opacity: .6;
    @include font-size(.75rem);
  }
}
