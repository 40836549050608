
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[fixed-grid] {

  [tile] {
    // padding-bottom: 150%;
    position: relative;
  }
}
