
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[sidebar-component] {
  [sidebar-item-component], & {
    @each $color-name, $color-val in $agugiaro-figna-colors {
      .sidebar-link-#{$color-name} {
        transition: all 0.3s;
        color: $black;
        &:hover, &:active, &:focus, &:visited {
          color: $color-val;
        }
        &.collapsed {
          color: $black;
        }
      }
    }

    .subitem {
      & > a:not(.collapsed),
      & > a:not(.collapsed) > i {
        transition: all .25s;
      }

      & > a:not(.collapsed) > i {
        transform: rotate(90deg);
      }
    }

    .url-lg {
      font-family: $italia-lt-font;
    }
  }
}
