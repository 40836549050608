
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-article] {

  .play-icon {
    @include font-size(4.5rem);
    color: $white;
    text-shadow: 1px 1px 7px $black;
    opacity: 0.9;
    transition: 0.6s all;

    &:hover {
      opacity: 0.6;
    }
  }

  @include media-breakpoint-down(xs) {
    .play-icon {
      @include font-size(3.5rem);
    }
  }
}


