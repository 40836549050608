
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[user-notices-component] {
  
  .user-notice {

    .user-notice-reference {
      cursor: pointer;
    }

    .user-notice-icon {
      width: 2.5rem;
      height: 2.5rem;
      color: $white;
      border-radius: 100%;
      background-color: $primary;
    }

    .user-notice-status {
      border-radius: 100%;
      background-color: $primary;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }

    &.unviewed {
      background-color: $gray-100;
    }

    &.read {
      opacity: 0.5;

      .user-notice-status {
        border: 1px solid $primary;
        background-color: transparent;
      }
    }

  }
}
