
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[modal-registration-component] {
  .close-icon {
    font-family: $italia-lt-font;
  }

  .description {
    @include font-size(1.5rem);
  }

  .link {
    @include font-size(1.25rem);
  }

  .separator-line {
    border-top: 1px solid #725249;
    width: 100%;
  }

  .separator-text {
    @include font-size(1rem);
  }

  .item-img {
    max-width: 3rem;
  }

  .item-title {
    font-family: $italia-lt-font;
    @include font-size(1.75rem);
    font-weight: bold;
  }
}
