
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-after-signup-component] {
  [generic-form-component] {
    form > div.col-12 {
      justify-content: center !important;
      margin-right: auto !important;
    }
  }
}
