
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[keypoint-item] {

  p {
    margin-bottom: 0;
  }

  .point-image {
    width: 100%;
    height: auto;
  }

  .amount {
    @include font-size(4.5rem);
    font-weight: 400;
  }

  .point-title,
  .point-description {
    @include font-size(1rem);
    color: $medium-grey !important;
  }

  .point-title {
    text-transform: uppercase;
  }

  .point-description {
    font-weight: 300;
    font-style: italic;

    &, & > p {
      opacity: .6;
    }
  }

  .has-url {
    .point-image {
      transition: transform .5s ease-in-out;
    }
    .point-title {
      transition: color .5s ease-in-out;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $primary;

      .point-image {
        transform: scale(1.1);
      }

      .point-title {
        color: $primary;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .point-title,
    .point-description {
      @include font-size(.875rem);
    }
  }
}
