
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[video-content-component] {
  color: $white;

  .icon {
    width: 107px;
    height: 100px;
  }
}
