
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[list-users-pizzaiolo-del-cambiamento-component] {
  h6 {
    font-size: 1.375 rem;
  }

  h3 {
    font-family: $neutra-display-font;
  }

  .avatar-container {
    width: 65%;
  }

  .avatar {
    background-color: $military-green;
  }

  .user-text {
    text-transform: capitalize;
  }
}

