
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[page-pizzaiolo-registration-component] {
  .progress-bar {
    top: 0;
    left: 0;
    height: .3rem;
    transition: width .5s ease-in-out;
  }

  [text-section-component] {
    h2 {
      @include font-size(2.5rem);
      font-family: $italia-lt-font;
    }
  }
}
