
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.content-wrapper {
  --min-height: 28rem;
  min-height: 100vw;

  @include media-breakpoint-up(md) {
    min-height: var(--min-height);
  }
}

.banner-description {
  @include media-breakpoint-up(md) {
    @include font-size(2.125rem);
  }
}
