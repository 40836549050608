
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[miniformat-badge-component] {
  background-color: $primary;
  color: $white;
  font-size: 0.75rem;
}
