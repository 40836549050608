
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

$icon-size: 2.625rem;

.main-img {
  padding-bottom: 57.6%;
}

.recipe-main-image {
  object-fit: cover;
  top: 0;
  left: 0;
}

.category-wrapper {
  line-height: 1;
}

.icon-sizer {
  height: $icon-size;
  width: $icon-size;
}

.owner-avatar {
  height: 4rem;
  width: 4rem;
}

.step-counter {
  @include font-size(1.75rem);
}

.recipe-img {
  padding-bottom: 52.5%;
  transition: opacity 0.35s;

  @include hover {
    opacity: 0.92;
  }
}

.accessory-carousel-item {
  width: 38.5%;
  padding-bottom: 38.5%;

  @include media-breakpoint-up(sm) {
    width: 23.5%;
    padding-bottom: 23.5%;
  }
}

.quote-avatar {
  width: 6rem;
  height: 6rem;
}

.quote-description {
  @include font-size(1.375rem);
  line-height: 1.45;
}

.btn-filter {
  color: $black;
  border-color: $black;

  &:hover,
  &:active,
  &:focus,
  &.active {
    color: $white;
    border-color: transparent;
    background-color: $red;
  }
}

.recipe-info-detail {
  &:last-child {
    padding-top: 0;
  }

  @include media-breakpoint-down(xs) {
    width: 50%;
    &:last-child {
      padding-top: 1rem;
    }
  }
}


.share-interactions {
  ::v-deep {
    .share-icon .fa-lg {
      @include font-size(1.25rem);

      @include media-breakpoint-up(lg) {
        @include font-size(1rem);
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .recipe-times {
    .border-right {
      border-right: none !important;
    }
  }
}
