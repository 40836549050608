
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

[embed-advanced-component] {

  .embed_notification {
    position: absolute;
    right: 1rem;
    bottom: 0.75rem;
    .btn { 
      border-radius: .25rem; 

      i {
        @include font-size(1.5rem);
        margin-top: 1px;
      }
    }
  } 

  .embed-fullscreen__close-icon {
    position: absolute;
    right: 0;
    top: 0;
    @include font-size(2rem);
  }

  .embed-fullscreen__controls {

    i {
      @include font-size(1.5rem);
    }
  }
  
  .unsupported-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 1090;
    background-color: $black;
  }

  .embed_content {
    width: 100vw;
  }

  @media (min-aspect-ratio: 16/9) {
    .embed_content {
      height: 100vh;
      width: calc((16 / 9) * 100vh) !important;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
}
