
    @import "tenants/tenants-agugiaro-e-figna/cinque_stagioni/javascript/src/stylesheets/cinque_stagioni_variables.scss";
    @import "app/javascript/src/stylesheets/fandom_mixins.scss";
  

.play-icon {
  font-size: 8rem;
}

.container-bg {
  background-size: cover;
  background-position: center top;
}

.ar-8by7 {
  padding-bottom: calc(7 / 8 * 100%);
}

/* Forced square rappresentation on mobile */
@include media-breakpoint-down(md) {
  .ar-16by9,
  .ar-4by3,
  .ar-sm-1by1 {
    padding-bottom: 100%;
  }
}

/* IE11 hack */
@include ie {
  > div > .row {
    max-height: 1080px;
  }

  .media-column,
  .text-column {
    min-height: inherit;
    max-height: 1080px;
  }
}
